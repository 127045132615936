import { lazy, Suspense } from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import NotFound from './NotFound';

const Client = lazy(() => import('./client'));
const Backend = lazy(() => import('./backend/pages'));
const Fallback = <></>;

export default function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route
          path="/"
          element={
            <Suspense fallback={Fallback}>
              <Client />
            </Suspense>
          }
        />
        <Route
          path="/backend/*"
          element={
            <Suspense fallback={Fallback}>
              <Backend />
            </Suspense>
          }
        />
        <Route path="*" element={<NotFound />} />
      </Routes>
    </BrowserRouter>
  );
}

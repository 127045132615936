import styled from './backend/styled';

const S = {
  NotFound: styled.div`
    width: 100vw;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: Menlo, Cascadia Code, Monaco, monospace;
    font-size: 80px;
    font-weight: 800;
    margin-top: -50px;
    color: #333;
  `,
};

export default function NotFound() {
  return <S.NotFound>404</S.NotFound>;
}

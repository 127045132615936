import * as styledComponents from 'styled-components';
import type { Theme } from './theme';

const {
  default: styled,
  css,
  createGlobalStyle,
} = styledComponents as styledComponents.ThemedStyledComponentsModule<Theme>;
export default styled;
export { css, createGlobalStyle };
